import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "personnelReport",
  uid: "id",
  prefetch: false,
  staleAfter: 300,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/reports/personnel?:queryString",
  fetchActions: [],
  forceFetchActions: [],
  initialData: {
    _queryString: {},
    _lastStatsFetch: null,
    _stats: null,
    _lastStatsResource: null,
    _statsStaleAfter: 30000,
    _shouldFetchStats: true,
    _isStatsLoading: false,
    _isLoading: false,
  },
  urlParamSelectors: ["selectPersonnelReportFormattedQueryString"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "PERSONNELREPORT_FETCH_STATS_STARTED":
      case "PERSONNELREPORT_FETCH_STATS_ABORTED":
      case "PERSONNELREPORT_FETCH_STATS_ERROR":
      case "PERSONNELREPORT_QUERY_STRING_UPDATED":
      case "PERSONNELREPORT_INIT_ON_REPORT_PAGE":
        return Object.assign({}, state, payload);
      case "PERSONNELREPORT_FETCH_STATS_FINISHED":
        return Object.assign({}, payload);
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  addons: {
    doPersonnelReportDownloadAsCsv: () => ({ store }) => {
      const ignoreFields = ["id"];
      const data = store.selectPersonnelReportItems();
      if (!data || !data.length) return null;
      const csvRows = [];

      const fixCommas = (value) => {
        if (value && typeof value === "string" && value.indexOf(",") !== -1)
          return `"${value}"`;
        return value;
      };

      const setHeaders = (item) => {
        const head = [];
        Object.keys(item).forEach((key) => {
          if (ignoreFields.indexOf(key) === -1)
            head.push(fixCommas(key.toUpperCase()));
        });
        csvRows.push(head.join(","));
      };

      data.forEach((item, i) => {
        if (i === 0) setHeaders(item);
        const row = [];
        Object.keys(item).forEach((key) => {
          if (ignoreFields.indexOf(key) === -1) row.push(fixCommas(item[key]));
        });
        csvRows.push(row.join(","));
      });

      const csv = csvRows.join("\n");
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, "export.csv");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    doPersonnelReportFetchStats: () => ({ dispatch, store, apiGet }) => {
      dispatch({
        type: "PERSONNELREPORT_FETCH_STATS_STARTED",
        payload: {
          _shouldFetchStats: false,
          _isStatsLoading: true,
          _isLoading: true,
        },
      });

      const flags = store.selectPersonnelReportFlags();
      const url = store.selectPersonnelReportFetchStatsUrl();
      const isStale = store.selectPersonnelReportIsStatsStale();
      const lastResource = store.selectPersonnelReportLastStatsResource();

      if (url.indexOf("/:") !== -1) {
        dispatch({
          type: "PERSONNELREPORT_FETCH_STATS_ABORTED",
          payload: {
            _isStatsLoading: false,
          },
        });
      } else if (!isStale && url === lastResource) {
        dispatch({
          type: "PERSONNELREPORT_FETCH_STATS_ABORTED",
          payload: {
            _isStatsLoading: false,
          },
        });
      } else {
        apiGet(url, (err, response, body) => {
          if (err || response.statusCode !== 200) {
            dispatch({
              type: "PERSONNELREPORT_FETCH_STATS_ERROR",
              payload: {
                _err: { err: err, response: response },
                _isStatsLoading: false,
                notification: {
                  statusCode: response.statusCode,
                },
              },
            });
          } else {
            const data = JSON.parse(body);
            dispatch({
              type: "PERSONNELREPORT_FETCH_STATS_FINISHED",
              payload: {
                ...flags,
                _lastStatsResource: url,
                _lastStatsFetch: new Date(),
                _isStatsLoading: false,
                _isLoading: false,
                _queryString: {},
                _stats: data,
              },
            });
          }
        });
      }
    },

    doPersonnelReportUpdateQueryString: (update) => ({ dispatch, store }) => {
      const q = store.selectPersonnelReportQueryString();
      dispatch({
        type: "PERSONNELREPORT_QUERY_STRING_UPDATED",
        payload: {
          _queryString: {
            ...q,
            ...update,
          },
          _shouldFetch: true,
        },
      });
    },

    selectPersonnelReportLastStatsResource: (state) => {
      return state.personnelReport._lastStatsResource;
    },

    selectPersonnelReportStatsStaleAfter: (state) => {
      return state.personnelReport._statsStaleAfter;
    },

    selectPersonnelReportLastStatsFetch: (state) => {
      return state.personnelReport._lastStatsFetch;
    },

    selectPersonnelReportIsStatsStale: createSelector(
      "selectAppTime",
      "selectPersonnelReportStatsStaleAfter",
      "selectPersonnelReportLastStatsFetch",
      (now, staleAfter, lastFetch) => {
        return now - new Date(lastFetch) > staleAfter;
      }
    ),

    selectPersonnelReportIsStatsLoading: (state) => {
      return state.personnelReport._isStatsLoading;
    },

    selectPersonnelReportQueryString: (state) => {
      return state.personnelReport._queryString;
    },

    selectPersonnelReportFormattedQueryString: createSelector(
      "selectPersonnelReportQueryString",
      "selectPersonnelReportStats",
      (queryString, stats) => {
        if (!queryString || !stats) return "";
        const qsItems = [];
        for (var key in queryString) {
          const val = queryString[key];

          // if there's nothing at this key, bail
          if (!val) continue;

          // if it's an array with nothing in it it will fall to here, if nothing there then bail
          if (val.hasOwnProperty("length") && !val.length) continue;

          // if it's one of our time fields then handle that
          if (
            ["flight_time", "last_time_pilot", "last_time_vo", "part_107_expiration"].indexOf(
              key
            ) !== -1
          ) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(
                `${key}_from=${encodeURIComponent(val.from.toISOString())}`
              );
            // if there is a value in the to key then add that
            if (val.to)
              qsItems.push(
                `${key}_to=${encodeURIComponent(val.to.toISOString())}`
              );
            continue;
          }

          // if it's one of our number fields then handle that
          if (["flight_count_pilot", "flight_count_vo"].indexOf(key) !== -1) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(`${key}_from=${encodeURIComponent(val.from)}`);
            // if there is a value in the to key and it's not the default then add that
            if (
              key === "flight_count_pilot" &&
              val.to &&
              val.to < stats.max_flight_count_pilot
            )
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            if (
              key === "flight_count_vo" &&
              val.to &&
              val.to < stats.max_flight_count_vo
            )
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            continue;
          }

          // otherwise, just add it to the querystring as-is
          qsItems.push(`${key}=${encodeURIComponent(val)}`);
        }

        if (!qsItems.length) return {};

        return {
          queryString: qsItems.join("&"),
        };
      }
    ),

    selectPersonnelReportStats: (state) => {
      return state.personnelReport._stats || null;
    },

    selectPersonnelReportFetchStatsUrl: createSelector(
      "selectRouteParams",
      (params) => {
        const availableParams = {
          ...params,
        };
        let url = "/orgs/:orgSlug/reports/personnel/stats";
        Object.keys(availableParams).forEach((key) => {
          url = url.replace(`:${key}`, availableParams[key]);
        });
        return url;
      }
    ),

    selectPersonnelReportParameters: createSelector(
      "selectPersonnelReportStats",
      "selectDomainsItemsByGroup",
      "selectOrgsByRoute",
      (stats, domainsByGroup, org) => {
        if (!stats || !org) return null;
        const parameters = [];
        parameters.push({
          type: "checkbox-search",
          column: "org_name",
          title: "Organization",
          options: stats.orgs.map((o) => {
            return { id: o, val: o, checkedByDefault: org.name === o };
          }),
        });
        parameters.push({
          type: "text-search",
          column: "name",
          title: "Personnel Name",
        });
        parameters.push({
          type: "text-search",
          column: "email",
          title: "E-Mail",
        });
        parameters.push({
          type: "text-search",
          column: "phone",
          title: "Phone",
        });
        parameters.push({
          type: "date-range-search",
          column: "part_107_expiration",
          title: "Part 107 Expiration",
        });
        parameters.push({
          type: "date-range-search",
          column: "last_time_pilot",
          title: "Last Flight as Pilot",
        });
        parameters.push({
          type: "date-range-search",
          column: "flight_time",
          title: "Flight Minutes",
        });
        parameters.push({
          type: "number-range-search",
          column: "flight_count_pilot",
          title: "Flight Count as Pilot",
          min: 0,
          max: stats.max_flight_count_pilot || 0,
        });
        parameters.push({
          type: "date-range-search",
          column: "last_time_vo",
          title: "Last Flight as VO",
        });
        parameters.push({
          type: "number-range-search",
          column: "flight_count_vo",
          title: "Flight Count as VO",
          min: 0,
          max: stats.max_flight_count_vo || 0,
        });
        parameters.push({
          type: "checkbox-search",
          column: "approval_roles",
          title: "Approval Roles Held",
          options: domainsByGroup["approval_role"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        return parameters;
      }
    ),
    selectPersonnelReportNotHidden: state => {
      const items = [];
      Object.keys(state.personnelReport).forEach((key) => {
        if (key[0] !== "_" && !state.personnelReport[key].hidden) {
          items.push(state.personnelReport[key]);
        }
      });
      return items;
    },
    reactPersonnelReportShouldFetchStats: (state) => {
      if (state.personnelReport._shouldFetchStats)
        return { actionCreator: "doPersonnelReportFetchStats" };
    },
  },
});
