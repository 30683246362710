import { capitalize, find } from "lodash";
import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import Loader from "../../../../app-components/loader";
import DateInput from "../../../../app-components/schema-form/date-input";
import SelectInput from "../../../../app-components/schema-form/select-input";
import TextInput from "../../../../app-components/schema-form/text-input";
import PreparedBySigItem from "../../../../modules/missions/details/approvals-tab/prepared-by-sig-item";
import PrintButton from "../../../../app-components/print-button/print-button";
import "./efTwentyTwo.css";

const definitions = [
  {
    title: "AUTHORITY",
    definition: `33 USC § 576c, Corps of Engineers Operation of Small Unmanned Aircraft Systems; AR 95-1, Flight Regulations; USACE Aviation Policy Letter 95-1-1`,
  },
  {
    title: "PRINCIPAL PURPOSE",
    definition: `To record Small Unmanned Aircraft System Crewmember performance during evaluation and training events.`,
  },
  {
    title: "ROUTINE USES",
    definition: `This form will controlled by the FOA ATPM and stored electronically in the MIS for Aviation and Remote Systems (MARS) as part of each crewmember’s Flight
        Training Folder. ATPMs may also retain hard copies of this and all FTF forms. `,
  },
  {
    title: "DISCLOSURE",
    definition: `Voluntary; this form is not intended for use in personnel actions outside of SUAS Crewmember assignments and designations. `,
  },
];
const eventOptions = [
  {
    value: "new_7120",
    name: "new_7120",
    label: "New EF 7120 signed and posted to FTF",
  },
  {
    value: "new_6150",
    name: "new_6150",
    label: "New EF 6150 signed and posted to FTF",
  },
  { value: "sqc", name: "sqc", label: "SUAS qualification complete" },
  { value: "atp_met", name: "atp_met", label: "ATP annual requirements met" },
  {
    value: "atp_not_met",
    name: "atp_not_met",
    label: "ATP annual requirements not met",
  },
  {
    value: "night",
    name: "night",
    label: "Night flight authorized IAW USACE Aviation Policy Letter 95-1-1",
  },
  {
    value: "bvlos_day",
    name: "bvlos_day",
    label: "BVLOS-Day flight authorized IAW Aviation Policy Letter 95-1-1",
  },
  {
    value: "bvlos_night",
    name: "bvlos_night",
    label: "BVLOS-Night flight authorized IAW Aviation Policy Letter 95-1-1",
  },
  {
    value: "foa",
    name: "foa",
    label: "FOA-specific mission training complete",
  },
  {
    value: "ext_30",
    name: "ext_30",
    label: "30-day extension for {atp_requirement} - Approved by ATPM",
  },
  {
    value: "ext_45",
    name: "ext_45",
    label: "45-day extension for {atp_requirement} - Approved by APM",
  },
  {
    value: "pfe",
    name: "pfe",
    label: "Proficiency Flight Exam completed to regain currency",
  },
  {
    value: "crew_accident",
    name: "crew_accident",
    label: "Crewmember involved in accident or incident; EF 178 posted",
  },
  {
    value: "post_mishap",
    name: "post_mishap",
    label:
      "Post Mishap Flight Exam completed; return to flight duty authorized",
  },
  {
    value: "rpi",
    name: "rpi",
    label: "Designated as a Remote Pilot Instructor for {current_org}",
  },
  {
    value: "srpi",
    name: "srpi",
    label:
      "Designated as a Standardization Remote Pilot Instructor for {current_org}",
  },
  {
    value: "mbo",
    name: "mbo",
    label: "Designated as a Mission Briefing Officer for {current_org}",
  },
  {
    value: "maa",
    name: "maa",
    label: "Designated as a Mission Approval Authority for {current_org}",
  },
  { value: "transfer", name: "transfer", label: "Transferred from {prev_org}" },
  { value: "remove", name: "remove", label: "Removed from ATP IAW APL 95-1-1" },
  { value: "free_text", name: "free_text", label: "{FREE TEXT}" },
];

const variableOptions = [
  "ext_30",
  "ext_45",
  "rpi",
  "srpi",
  "mbo",
  "maa",
  "transfer",
  "free_text",
];

const reducer = (state, { type, payload, row }) => {
  switch (type) {
    case "UPDATE_INPUT":
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, payload);
    case "UPDATE_ROW_INPUT":
      let newRows = state.rows;
      newRows[row] = { ...newRows[row], ...payload };
      return { ...state, rows: newRows };
    case "ADD_ROW":
      return {
        ...state,
        rows: [
          ...state.rows,
          {
            date: "",
            event: "",
            atpm_sig: "",
            suac_sig: "",
            list_order: state.rows.length,
          },
        ],
      };
    default:
      return state;
  }
};

const EFTwentyTwo = ({
  doNestedDialogClose,
  doEfTwentyTwoSave,
  doEfTwentyTwoFetch,
  efTwentyTwoItems: data,
  doEfTwentyTwoEntrySign,
  efTwentyItems,
  isProfileActiveAtpm: isPilotsAtpm,
  profileActiveData,
  profileId,
  efTwentyTwoIsSaving,
  profilesAll,
  doFetchAtpmDashboardById,
}) => {
  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    let rows =
      data && data.length > 0
        ? data
        : [
            {
              date: "",
              event: "",
              atpm_sig: "",
              suac_sig: "",
              list_order: 0,
            },
          ];

    dispatch({
      type: "INITIALIZE_MODAL",
      payload: {
        rows,
      },
    });
  }, [data]);

  const _onRowChange = (input, idx) => {
    if (input.property === "event") {
      dispatch({
        type: "UPDATE_ROW_INPUT",
        payload: { [input.property]: input.value, event_variable: null },
        row: idx,
      });
    } else {
      dispatch({
        type: "UPDATE_ROW_INPUT",
        payload: { [input.property]: input.value },
        row: idx,
      });
    }
  };

  const _onChange = (input) => {
    dispatch({
      type: "UPDATE_INPUT",
      payload: { [input.property]: input.value },
    });
  };

  const _condenseValue = (row) => {
    if (row.event === "free_text") {
      return row.event_variable;
    } else {
      let tempLabel = find(
        eventOptions,
        (obj) => obj.value === row.event
      ).label;
      let tempEvent = tempLabel.split("{")[1].split("}")[0];
      return tempLabel.replace(`{${tempEvent}}`, row.event_variable);
    }
  };

  const _getEventLabel = (row) =>
    find(eventOptions, (obj) => obj.value === row.event) &&
    find(eventOptions, (obj) => obj.value === row.event).label;

  const parseSig = (sig) => {
    if (!sig) return "";
    sig = JSON.parse(atob(sig.split(".")[0]));

    let signer = find(profilesAll, { keycloak_id: sig.keycloak_id });
    let initials = signer.name.split(" ").map((word) => capitalize(word[0]));
    return [`${initials.join("")}`, "(Digitally Signed)"];
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <div className="d-flex flex-column">
          <h5 className="modal-title">SUAC Training Record</h5>
          <h6 className="text-muted mt-2 mb-0">
            For use of this form see USACE Aviation Policy Letter 95-1-1
          </h6>
          <h6 className="text-muted mt-2">
            The proponent agency is HQ Aviation
          </h6>
        </div>
        <div className="d-flex flex-column h-100">
          <button
            onClick={doNestedDialogClose}
            className="close"
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>

          <PrintButton
            className="btn-sm mt-auto mb-0"
            options={[
              {
                resultFileName: "EF 7122 SUAC Training Record",
                file: "ef-twentytwo.template.json",
                stateFields: { eventOption: eventOptions },
              },
            ]}
            state={{
              ...state,
              rows: state.rows
                ? state.rows.map((r) => {
                    return {
                      ...r,
                      atpm_sig_parsed_22: parseSig(r.atpm_sig),
                      suac_sig_parsed_22: parseSig(r.suac_sig),
                    };
                  })
                : state.rows,
            }}
          >
            <i className="mdi mdi-printer mr-2" />
            Fill & Print EF 7122
          </PrintButton>
        </div>
      </div>
      <div className="border-bottom mb-3 mt-3">
        <div className="col-lg">
          {definitions.map((defObj, i) => {
            return (
              <div className="d-inline-flex pb-2" key={`${defObj.title}_${i}`}>
                <p className="m-0 mr-2 text-nowrap">
                  <strong>{defObj.title}:</strong>
                </p>
                <p className="m-0">{defObj.definition}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="modal-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm">
              <TextInput
                value={
                  efTwentyItems && efTwentyItems.length > 0
                    ? efTwentyItems[0].name
                    : ""
                }
                property="name"
                onChange={_onChange}
                schema={{
                  title: "Name",
                  description: "Autofilled from EF-7120 form.",
                }}
                displayOnly
              />
            </div>
            <div className="col-sm">
              <SelectInput
                onChange={_onChange}
                value={
                  efTwentyItems && efTwentyItems.length > 0
                    ? efTwentyItems[0].atp_month
                    : ""
                }
                property="month"
                options={[
                  { value: "01", name: "jan", label: "January" },
                  { value: "02", name: "feb", label: "February" },
                  { value: "03", name: "mar", label: "March" },
                  { value: "04", name: "apr", label: "April" },
                  { value: "05", name: "may", label: "May" },
                  { value: "06", name: "jun", label: "June" },
                  { value: "07", name: "jul", label: "July" },
                  { value: "08", name: "aug", label: "August" },
                  { value: "09", name: "sept", label: "September" },
                  { value: "10", name: "oct", label: "October" },
                  { value: "11", name: "nov", label: "November" },
                  { value: "12", name: "dec", label: "December" },
                ]}
                displayOnly
                schema={{
                  title: "First Month of ATP Year",
                  description: "Autofilled from EF-7120 form.",
                }}
              />
            </div>
          </div>
          <div className="row mb-3 ">
            <div className="col-lg d-flex justify-content-end">
              <button
                className="btn btn-success ml-2"
                onClick={(e) => {
                  dispatch({
                    type: "ADD_ROW",
                    payload: { rowLength: state.rows.length },
                  });
                }}
              >
                Add Row
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <table className="w-100">
                <thead>
                  <tr>
                    <th className="border p-1" scope="col">
                      Date
                    </th>
                    <th
                      className="border p-1"
                      scope="col"
                      style={{ width: "40%" }}
                    >
                      Event
                    </th>
                    <th className="border p-1" scope="col">
                      ATPM Signature
                    </th>
                    <th className="border p-1" scope="col">
                      SUAC Signature
                    </th>
                    <th className="border p-1" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {state.rows &&
                    state.rows.map((row, i) => {
                      let displayOnly = !!(
                        state.rows[i].atpm_sig || state.rows[i].suac_sig
                      );
                      let incomplete =
                        !state.rows[i].date || !state.rows[i].event;
                      return (
                        <tr
                          key={i}
                          className={state.rows[i].deleted && "strikeout"}
                        >
                          <td className="border p-1">
                            <DateInput
                              value={state.rows[i].date}
                              property="date"
                              onChange={(input) => _onRowChange(input, i)}
                              schema={{ title: "" }}
                              displayOnly={displayOnly || state.rows[i].deleted}
                              calendarPosition="right"
                            />
                          </td>
                          <td className="border p-1">
                            {(displayOnly || state.rows[i].deleted) &&
                            state.rows[i].event ? (
                              <div className="d-flex flex-column w-100">
                                <TextInput
                                  className="form-control"
                                  value={
                                    state.rows[i].event_variable
                                      ? _condenseValue(state.rows[i])
                                      : find(
                                          eventOptions,
                                          (obj) =>
                                            obj.value === state.rows[i].event
                                        ).label
                                  }
                                  displayOnly
                                  schema={{ title: "" }}
                                />
                              </div>
                            ) : (
                              <div className="d-flex flex-column w-100">
                                <SelectInput
                                  value={state.rows[i].event}
                                  property="event"
                                  onChange={(input) => _onRowChange(input, i)}
                                  schema={{ title: "" }}
                                  options={eventOptions}
                                  displayOnly={
                                    displayOnly || state.rows[i].deleted
                                  }
                                />
                                {state.rows[i].event &&
                                  variableOptions.indexOf(
                                    state.rows[i].event
                                  ) !== -1 && (
                                    <TextInput
                                      className="form-control"
                                      property="event_variable"
                                      onChange={(input) =>
                                        _onRowChange(input, i)
                                      }
                                      value={state.rows[i].event_variable}
                                      schema={{
                                        title: "",
                                      }}
                                      placeholder={`Enter ${_getEventLabel(
                                        state.rows[i]
                                      )
                                        .split("{")[1]
                                        .split("}")[0]
                                        .replace("_", " ")} here...`}
                                      displayOnly={
                                        displayOnly || state.rows[i].deleted
                                      }
                                    />
                                  )}
                              </div>
                            )}
                          </td>
                          <td className="border p-1">
                            {(row.id && !state.rows[i].deleted) ||
                            efTwentyTwoIsSaving ? (
                              efTwentyTwoIsSaving ? (
                                <Loader />
                              ) : (
                                <PreparedBySigItem
                                  sig={state.rows[i].atpm_sig}
                                  onSign={() =>
                                    doEfTwentyTwoEntrySign(
                                      {
                                        id: row.id,
                                        signature: "atpm",
                                      },
                                      doEfTwentyTwoFetch
                                    )
                                  }
                                  item={{}}
                                  disabled={
                                    !state.rows[i].atpm_sig && !isPilotsAtpm
                                  }
                                />
                              )
                            ) : (
                              <p className="text-muted">
                                Save your entry in this row to sign
                              </p>
                            )}
                          </td>
                          <td className="border p-1">
                            {(row.id && !state.rows[i].deleted) ||
                            efTwentyTwoIsSaving ? (
                              efTwentyTwoIsSaving ? (
                                <Loader />
                              ) : (
                                <PreparedBySigItem
                                  sig={state.rows[i].suac_sig}
                                  onSign={() =>
                                    doEfTwentyTwoEntrySign(
                                      {
                                        id: row.id,
                                        signature: "suac",
                                      },
                                      doEfTwentyTwoFetch
                                    )
                                  }
                                  item={{}}
                                  disabled={
                                    !state.rows[i].suac_sig &&
                                    profileId !== profileActiveData.id
                                  } // disable if no signature and not current user
                                />
                              )
                            ) : (
                              <p className="text-muted">
                                Save your entry in this row to sign
                              </p>
                            )}
                          </td>
                          <td className="border p-1">
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              {!state.rows[i].deleted && (
                                <>
                                  <button
                                    className={
                                      displayOnly || incomplete
                                        ? "btn btn-outline-secondary mt-1"
                                        : "btn btn-outline-info mt-1"
                                    }
                                    onClick={() =>
                                      !displayOnly &&
                                      doEfTwentyTwoSave(state.rows[i], () => {
                                        doFetchAtpmDashboardById(profileId);
                                      })
                                    }
                                    disabled={displayOnly || incomplete}
                                  >
                                    <i className="mdi mdi-content-save mdi-14px" />
                                  </button>
                                  <button
                                    className="btn btn-outline-dark mt-1"
                                    onClick={() =>
                                      doEfTwentyTwoSave(
                                        {
                                          ...state.rows[i],
                                          atpm_sig: null,
                                          suac_sig: null,
                                        },
                                        () => {
                                          doFetchAtpmDashboardById(profileId);
                                        }
                                      )
                                    }
                                  >
                                    <i className="mdi mdi-lock-open mdi-14px" />
                                  </button>
                                </>
                              )}
                              {row.id && (
                                <button
                                  className={`btn btn-outline-${
                                    state.rows[i].deleted ? "dark" : "danger"
                                  } mt-1`}
                                  onClick={() =>
                                    doEfTwentyTwoSave(
                                      {
                                        ...state.rows[i],
                                        deleted: state.rows[i].deleted ? 0 : 1,
                                      },
                                      () => {
                                        doFetchAtpmDashboardById(profileId);
                                      }
                                    )
                                  }
                                >
                                  <i
                                    className={`mdi mdi-${
                                      state.rows[i].deleted
                                        ? "undo"
                                        : "trash-can-outline"
                                    } mdi-14px`}
                                  />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doNestedDialogClose",
  "doEfTwentyTwoSave",
  "doEfTwentyTwoFetch",
  "doEfTwentyTwoEntrySign",
  "selectEfTwentyItems",
  "selectEfTwentyTwoItems",
  "selectIsProfileActiveAtpm",
  "selectProfileId",
  "selectProfileActiveData",
  "selectEfTwentyTwoIsSaving",
  "selectProfilesAll",
  "doFetchAtpmDashboardById",
  EFTwentyTwo
);
