import React from "react";
import { connect } from "redux-bundler-react";
import Grid from "../../_shared/grid";
import { customComparator } from "../../_shared/helper";
import { capitalize } from "lodash";
export default connect(
  "selectAircraftReportItems",
  "selectAircraftReportIsLoading",
  ({ aircraftReportItems: data, aircraftReportIsLoading }) => {
    const config = {
      columnDefs: [
        {
          resizable: true,
          sortable: true,
          headerName: "Org",
          field: "org_name",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Name",
          field: "name",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Make",
          field: "make",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Model",
          field: "model",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Status",
          field: "deactivated",
          comparator: customComparator,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "AWR Number",
          field: "awr_number",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Serial No.",
          field: "serial_no",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "FAA ID",
          field: "faa_id",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Barcode",
          field: "barcode",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "HQ Barcode",
          field: "hq_avn_barcode",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Group Type",
          field: "group_type",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Operational",
          field: "operational",
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Cost",
          field: "cost",
          cellRenderer: (props) => {
            if (typeof props.data.cost !== 'number') return "N/A";
            return props.data.cost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Service Entry Date",
          field: "service_entry_date",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Last Flight",
          field: "last_flight_date",
          valueFormatter: (cell) => {
            if (!cell.value) return null;
            const d = new Date(cell.value);
            return d.toLocaleDateString();
          },
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Flight Minutes",
          field: "total_flight_minutes",
          comparator: (valueA, valueB) => valueA - valueB,
        },
        {
          resizable: true,
          sortable: true,
          headerName: "Mission Count (All time)",
          field: "mission_count",
          comparator: (valueA, valueB) => valueA - valueB,
        },
      ],
    };
    return (
      <Grid
        isLoading={aircraftReportIsLoading}
        config={config}
        data={data}
        style={{ height: `${window.innerHeight * 0.8}px`, width: "100%" }}
      />
    );
  }
);
