import createRestBundle from "./create-rest-bundle";

export default createRestBundle({
  name: "allEquipment",
  uid: "id",
  prefetch: true,
  staleAfter: 1000,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/allEquipment",
  putTemplate: "",
  postTemplate: "",
  deleteTemplate: "",
  fetchActions: ["URL_UPDATED", "AUTH_LOGGED_IN"],
  forceFetchActions: [],
  urlParamSelectors: [],
  addons: {
    doAllEquipmentDownloadAsCsv: () => ({ store }) => {
      const ignoreFields = ["id", 'parent_orgs', 'operational_id', 'org_id'];
      const data = store.selectAllEquipmentItems();
      if (!data || !data.length) return null;
      const csvRows = [];

      const fixCommas = (value) => {
        if (value && typeof value === "string" && value.indexOf(",") !== -1)
          return `"${value}"`;
        return value;
      };

      const setHeaders = (item) => {
        const head = [];
        Object.keys(item).forEach((key) => {
          if (ignoreFields.indexOf(key) === -1)
            head.push(fixCommas(key.toUpperCase()));
        });
        csvRows.push(head.join(","));
      };

      data.forEach((item, i) => {
        if (i === 0) setHeaders(item);
        const row = [];
        Object.keys(item).forEach((key) => {
          if (ignoreFields.indexOf(key) === -1) row.push(fixCommas(item[key]));
        });
        csvRows.push(row.join(","));
      });

      const csv = csvRows.join("\n");
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, "export.csv");
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "export.csv");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  }
});
