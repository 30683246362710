import React, { useEffect } from "react";
import pkg from "../../../package.json";
import classnames from "../../utils/classnames";
import { connect } from "redux-bundler-react";
import Dropdown from "./nav-dropdown";
import DropdownItem from "./nav-dropdown-item";
import Help from "../help/help-dialog";
import About from "../about/about-dialog";
import RoleFilter from "../context-providers/role-filter";
import downtimeDialog from "../downtime/downtime-dialog";
import { reduce } from "lodash";

const badgeStyle = {
  display: "inline-block",
  padding: "0.25em 0.6em",
  fontSize: "75%",
  fontWeight: 700,
  lineHeight: 1,
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
  margin: "auto 0",
};
const Navbar = ({
  aboutDontshow,
  doDialogOpen,
  doFetchProfileApprovalRoles,
  doReadFileFetch,
  doPlatformsFetchByOrgAndProfile,
  doUiToggleSidebarShow,
  isLoggedIn,
  backlogItems,
  doLogout,
  orgsByRoute,
  doSetPreviousRoute,
  jobsNewCount,
  readFileCountObject,
  coaDocsCounter,
  gradeSlipUnviewedItemsByEvalType,
}) => {
  useEffect(() => {
    doFetchProfileApprovalRoles();
    doReadFileFetch(doPlatformsFetchByOrgAndProfile);
    if (!aboutDontshow) {
      doDialogOpen({ content: About, props: { scrollable: true }, size: "xl" });
    }
  }, []);

  if (!isLoggedIn) return null;
  const inOrg = !!orgsByRoute;

  const sidebarExpanderClass = classnames({
    "navbar-toggler": true,
    "sidebar-toggler": true,
    "d-md-down-none": true,
    hidden: !inOrg,
  });

  const totalUnviewedForms = reduce(
    Object.values(gradeSlipUnviewedItemsByEvalType),
    (sum, n) => {
      sum += sum[true] ? sum[true] : 0 + n[true] ? n[true] : 0;
      return sum;
    },
    0
  );

  const backlogCount = backlogItems.length;
  const backlogCounterClass = backlogCount ? "danger" : "success";
  const readFileYellowClass =
    readFileCountObject.yellow > 0 ? "warning" : "success";
  const readFileRedClass = readFileCountObject.red > 0 ? "danger" : "success";
  const orgActive = inOrg ? orgsByRoute.name.toLowerCase() : "";

  const jobsCount = parseInt(jobsNewCount);
  const jobsCounterClass = jobsCount > 5 ? "danger" : "primary";

  let badgeColor =
    (backlogCount > 0 ||
      readFileCountObject.red > 0 ||
      coaDocsCounter.red > 0) &&
    (readFileCountObject.red >= readFileCountObject.yellow ||
      coaDocsCounter.red >= coaDocsCounter.yellow)
      ? "danger"
      : totalUnviewedForms > 0
      ? "danger"
      : readFileCountObject.yellow > 0 || coaDocsCounter.yellow > 0
      ? "warning"
      : "";

  return (
    <header className="app-header navbar">
      <button
        className="navbar-toggler sidebar-toggler d-lg-none mr-auto"
        type="button"
        onClick={doUiToggleSidebarShow}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <a className="navbar-brand" href="/">
        <div className="navbar-brand-full nasa">
          MARS{" "}
          <RoleFilter allowRoles={["APP.SYSADMIN"]}>{pkg.version} </RoleFilter>
        </div>
        <div className="navbar-brand-minimized">MARS</div>
      </a>

      <button
        className={sidebarExpanderClass}
        type="button"
        onClick={doUiToggleSidebarShow}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <ul className="nav navbar-nav d-md-down-none">
        <li
          className="nav-item px-3 nasa"
          style={{ color: "#20a8d8", fontSize: "17.5px" }}
        >
          {inOrg ? orgsByRoute.name : ""}
        </li>
      </ul>

      <ul className="nav navbar-nav ml-auto">
        <li className="nav-item px-2">
          <a href="/">
            <i className="mdi mdi-home-outline mr-2"></i>Home
          </a>
        </li>
        <li className="nav-item px-2">
          <a href="/configurations">
            <i className="mdi mdi-puzzle-outline mr-2"></i>Configurations
          </a>
        </li>
        <li className="nav-item px-2">
          <a href="/job-board">
            <i className="mdi mdi-clipboard-account-outline mr-2"></i>Job Board
          </a>
          <span
            className={`${
              jobsCount === 0 ? "d-none" : ""
            } ml-1 badge badge-pill badge-${jobsCounterClass}`}
          >
            {jobsCount > 5 ? "+5" : jobsCount}
          </span>
        </li>
        <Dropdown iconClass="ms ms-data-cube-o" label="Resources">
          <DropdownItem
            onClick={() => {
              doDialogOpen({
                content: About,
                props: { scrollable: true },
                size: "xl",
              });
            }}
          >
            <span className="btn-link text-dark">
              <i className="mdi mdi-information-outline mr-2"></i>About
            </span>
          </DropdownItem>
          {inOrg && (
            <DropdownItem>
              <a
                href={`/${orgActive}/message-board`}
                className="text-dark disabled"
              >
                <i className="mdi mdi-forum mr-2"></i>Message Board
              </a>
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              doDialogOpen({ content: Help, props: {}, size: "lg" });
            }}
          >
            <span className="btn-link text-dark">
              <i className="mdi mdi-help-circle-outline mr-2"></i>Report an
              Issue
            </span>
          </DropdownItem>
          <DropdownItem>
            <a href="/references" className="text-dark">
              <i className="mdi mdi-book-search-outline mr-2"></i>Reference
              Library
            </a>
          </DropdownItem>
          <RoleFilter allowRoles={["HQ.ADMIN"]}>
            <DropdownItem
              onClick={() => {
                doDialogOpen({
                  content: downtimeDialog,
                  props: {},
                  size: "lg",
                });
              }}
            >
              <i className="mdi mdi-timer-sand mr-2"></i>Admin Message
            </DropdownItem>
          </RoleFilter>
          <DropdownItem>
            <a href="/faqs" className="text-dark">
              <i className="mdi mdi-comment-question-outline mr-2"></i>FAQs
            </a>
          </DropdownItem>
          <RoleFilter allowRoles={["APP.SYSADMIN"]}>
            <DropdownItem>
              <a href="/release-notes" className="text-dark">
                <i className="mdi mdi-code-not-equal mr-2"></i>Release Notes
                Editor
              </a>
            </DropdownItem>
          </RoleFilter>
        </Dropdown>
        <Dropdown
          iconClass="mdi mdi-account-outline"
          label="Account"
          hasNotification={!!badgeColor}
          notificationColor={badgeColor}
        >
          <DropdownItem>
            <a
              href="/profile"
              onClick={doSetPreviousRoute}
              className="text-dark"
            >
              <i className="mdi mdi-account-cog-outline mr-2"></i>My Profile
              {totalUnviewedForms !== 0 && (
                <span className={`badge badge-pill badge-danger`}>
                  {totalUnviewedForms}
                </span>
              )}
            </a>
          </DropdownItem>
          <DropdownItem>
            <a
              href={`/read-file`}
              className="text-dark d-flex justify-content-between"
            >
              <div>
                <i className="mdi mdi-book mr-2"></i>Read File
              </div>
              <div style={{ position: "absolute", right: 10 }}>
                {readFileCountObject.red > 0 && (
                  <span
                    className={`badge-pill badge-${readFileRedClass} ${
                      readFileCountObject.yellow > 0 ? "mr-1" : ""
                    }`}
                    style={badgeStyle}
                  >
                    {readFileCountObject.red}
                  </span>
                )}
                {readFileCountObject.yellow > 0 && (
                  <span
                    className={`badge-pill badge-${readFileYellowClass}`}
                    style={badgeStyle}
                  >
                    {readFileCountObject.yellow}
                  </span>
                )}
              </div>
            </a>
          </DropdownItem>
          <DropdownItem>
            <a href="/backlog" className="text-dark">
              <i className="mdi mdi-format-list-checks mr-2"></i>My Backlog
              <span className={`badge badge-pill badge-${backlogCounterClass}`}>
                {backlogCount}
              </span>
            </a>
          </DropdownItem>

          <RoleFilter allowRoles={["HQ.ADMIN"]}>
            <DropdownItem>
              <a
                href="/coa-backlog"
                className="text-dark d-flex justify-content-between"
              >
                <div>
                  <i className="mdi mdi-format-list-checks mr-2"></i>COA Backlog
                </div>
                <div style={{ position: "absolute", right: 10 }}>
                  {coaDocsCounter.red > 0 && (
                    <span
                      className={`badge-pill badge-danger ${
                        coaDocsCounter.yellow > 0 ? "mr-1" : ""
                      }`}
                      style={badgeStyle}
                    >
                      {coaDocsCounter.red}
                    </span>
                  )}
                  {coaDocsCounter.yellow > 0 && (
                    <span
                      className={`badge-pill badge-warning`}
                      style={badgeStyle}
                    >
                      {coaDocsCounter.yellow}
                    </span>
                  )}
                </div>
              </a>
            </DropdownItem>
          </RoleFilter>
          <DropdownItem>
            <a href="/" onClick={doLogout} className="text-dark">
              <i className="mdi mdi-logout mr-2"></i>Logout
            </a>
          </DropdownItem>
        </Dropdown>
      </ul>
    </header>
  );
};

export default connect(
  "selectAboutDontshow",
  "selectOrgsByRoute",
  "selectIsLoggedIn",
  "selectBacklogItems",
  "doDialogOpen",
  "doUiToggleSidebarShow",
  "doLogout",
  "doSetPreviousRoute",
  "selectJobsNewCount",
  "selectReadFileCountObject",
  "doFetchProfileApprovalRoles",
  "doReadFileFetch",
  "doPlatformsFetchByOrgAndProfile",
  "selectCoaDocsCounter",
  "selectGradeSlipUnviewedItemsByEvalType",
  Navbar
);
