export const allowedIds = [
  "9f46f879-4304-4666-b591-df281829d371", //Dave Ibsen
  "07bc264c-33c0-4317-a18e-cfbb68bb9c4b", // Tina
  "35456516-3e8b-46ee-92fd-62acb64e0a0c", // Tyler Siskar
  "0fd186b9-9333-408b-89a1-08c809ccb6de", // CHRIS WILD
  "0095c11f-907c-48c1-9b5f-abe9880783b2", // BARRY
];

export const barryOrTyler = [
  "35456516-3e8b-46ee-92fd-62acb64e0a0c", // Tyler Siskar
  "0095c11f-907c-48c1-9b5f-abe9880783b2", // BARRY
]