import createRestBundle from "./create-rest-bundle";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import KML from "ol/format/KML";

export default createRestBundle({
  name: "mapKml",
  uid: "s3_key",
  prefetch: true,
  routeParam: null,
  getTemplate: "/orgs/:orgSlug/missions/:missionsSlug/kml",
  fetchActions: [
    "URL_UPDATED",
    "AUTH_LOGGED_IN",
    "FILE_IO_UPLOAD_FINISHED",
    "FILE_IO_UPLOAD_SET_URL",
    "FILE_IO_DELETE_FINISHED",
  ],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "MAP_KML_LOAD_FROM_MISSION_STARTED":
      case "MAP_KML_LOAD_FROM_MISSION_ABORTED":
      case "MAP_KML_LOAD_FROM_MISSION_ERROR":
      case "MAP_KML_LOAD_FROM_MISSION_FINISHED":
      case "MAP_KML_ADD_LAYER_FINISHED":
      case "MAP_KML_REMOVE_LAYER_FINISHED":
        return Object.assign({}, state, payload);
      case "MAP_INITIALIZED":
        return Object.assign({}, state, { _shouldAddToMap: true });
      case "MISSIONSLZ_FETCH_FINISHED":
      case "ACTIVE_TAB_UPDATE":
        return Object.assign({}, state, { _shouldLoadFromMission: true });

      default:
        return state;
    }
  },
  initialData: {
    _shouldLoadFromMission: false,
    _shouldAddToMap: false,
    _shouldAddToMapUpload: false,
  },
  addons: {
    /* Adding a feature requires checking if a new upload occurred, and then adding the new file as a layer, fires in useEffect in upload-kml-modal */
    doMapKmlAdd:
      () =>
      ({ dispatch, store }) => {
        let map = store.selectMap();
        let kmlLayers = store.selectMapKmlLayers()
          ? store.selectMapKmlLayers()
          : [];
        let files = store.selectMapKmlItems();
        files.forEach((file) => {
          if (!kmlLayers || !kmlLayers.includes(file.filename)) {
            const vectorLayer = new VectorLayer({
              source: new VectorSource({
                url: file.url,
                format: new KML(),
              }),
              visible: true,
              title: file.filename,
              name: file.filename,
            });
            kmlLayers.push(file.filename);
            map.addLayer(vectorLayer);
          }
        });

        dispatch({
          type: "MAP_KML_ADD_LAYER_FINISHED",
          payload: {
            _isLoading: false,
            _shouldAddToMapUpload: false,
            _kmlLayers: kmlLayers.length ? kmlLayers : undefined,
          },
        });
      },

    /* onDelete, file is removed from fileuploadcard, which triggers _shouldAddToMap to be true, which runs the doMapKmlLoadFromMission function */
    doMapKmlDelete:
      (url, s3_key, rel, admin) =>
      ({ dispatch, store }) => {
        const mapKmlItems = store.selectMapKmlItems();
        const map = store.selectMap();
        const kmlLayers = store.selectMapKmlLayers();
        mapKmlItems.forEach((file) => {
          if (file.s3_key === s3_key) {
            /** We need to remove the layer corresponding with this file */
            store
              .selectMap()
              .getLayers()
              .forEach((layer) => {
                if (layer && layer.get("name") === file.filename) {
                  map.removeLayer(layer);
                  let index = kmlLayers.indexOf(file.filename);
                  if (index > -1) {
                    kmlLayers.splice(index, 1);
                  }
                }
              });
          }
        });

        dispatch({
          type: "MAP_KML_REMOVE_LAYER_FINISHED",
          payload: {
            _isLoading: false,
            _shouldAddToMapUpload: false,
            _kmlLayers: kmlLayers,
          },
        });
      },

    /* this function clears any current kml layers, and then re adds them all. */
    doMapKmlLoadFromMission:
      () =>
      ({ dispatch, store }) => {
        /** Active tab index for AOI map is 3 currently, if on AOI map then fetch */
        if (store.selectActiveTab() === 3) {
          dispatch({
            type: "MAP_KML_LOAD_FROM_MISSION_STARTED",
            payload: {
              _shouldLoadFromMission: false,
              _isLoading: true,
            },
          });

          /* grab all kml files from fetch */
          const data = store.selectMapKmlItems();
          let map = store.selectMap();
          let kmlLayers = [];
          if (data && data.length && map) {
            data.forEach((item) => {
              const vectorLayer = new VectorLayer({
                source: new VectorSource({
                  url: item.url,
                  format: new KML(),
                }),
                visible: true,
                title: item.filename,
                name: item.filename,
              });
              kmlLayers.push(item.filename);
              map.addLayer(vectorLayer);
            });
          }
          dispatch({
            type: "MAP_KML_LOAD_FROM_MISSION_FINISHED",
            payload: {
              _isLoading: false,
              _kmlLayers: kmlLayers.length ? kmlLayers : undefined,
            },
          });
        }
      },

    selectMapKmlLayers: (state) => state.mapKml._kmlLayers,

    reactKmlShouldLoadFromMission: (state) => {
      if (state.mapKml._shouldLoadFromMission && state.mapKml._shouldAddToMap) {
        return { actionCreator: "doMapKmlLoadFromMission" };
      }
    },
  },
});
