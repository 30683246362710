import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import FileUploadCard from "../../../_shared/file-upload-card/file-upload-card";

const UploadKMLModal = ({ doDialogClose, mapKmlIsLoading, mapKmlItems, missionsByRoute, doFileIoDelete, doFileIoUpload, doMapKmlDelete, doMapKmlAdd, fileIoIsUploading }) => {
	const save = () => {
		doDialogClose();
	}

	useEffect(() => {
		/* Adding new layer if it already exists the adding function should handle for it */
		if (!fileIoIsUploading) doMapKmlAdd();
	}, [mapKmlItems.length, fileIoIsUploading])

	return (
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title">Add KML Files</h5>
				<button
					onClick={doDialogClose}
					className="close"
					type="button"
					aria-label="Close"
				>
					<span aria-hidden="true">×</span>
				</button>
			</div>

			<div className="modal-body">
				<FileUploadCard
					allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
					isLoading={mapKmlIsLoading}
					items={mapKmlItems}
					header={true}
					searchable={true}
					onUpload={doFileIoUpload}
					onRemove={(url, s3_key, rel, admin) => {
						doMapKmlDelete(url, s3_key, rel, admin);
						doFileIoDelete(url, s3_key, rel, admin);
					}}
					rel="kml_files"
					data={{ mission_id: missionsByRoute.id }}
				/>
			</div>

			<div className="modal-footer">
				<button
					onClick={doDialogClose}
					className="btn btn-sm btn-secondary"
					type="button"
				>
					Close
				</button>
				<button
					onClick={save}
					className="btn btn-sm btn-success"
					type="button"
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default connect(
	"doDialogClose",
	"doMissionsFiguresSave",
	"selectMapKmlIsLoading",
	"selectMapKmlItems",
	"doMapKmlDelete",
	"doMapKmlAdd",
	"doFileIoDelete",
	"doFileIoUpload",
	"selectMissionsByRoute",
	"selectFileIoIsUploading",
	UploadKMLModal
);
