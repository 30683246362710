import createRestBundle from "./create-rest-bundle";
import { createSelector } from "redux-bundler";

export default createRestBundle({
  name: "missionsReport",
  uid: "id",
  prefetch: false,
  staleAfter: 300,
  persist: false,
  routeParam: "",
  getTemplate: "/orgs/:orgSlug/reports/missions?:queryString",
  fetchActions: [],
  forceFetchActions: [],
  initialData: {
    _queryString: {},
    _lastStatsFetch: null,
    _stats: null,
    _lastStatsResource: null,
    _statsStaleAfter: 30000,
    _shouldFetchStats: true,
    _isStatsLoading: false,
    _isLoading: false,
    _data: [],
  },
  urlParamSelectors: ["selectMissionsReportFormattedQueryString"],
  reduceFurther: (state, { type, payload }) => {
    switch (type) {
      case "MISSIONSREPORT_FETCH_STATS_STARTED":
      case "MISSIONSREPORT_FETCH_STATS_ABORTED":
      case "MISSIONSREPORT_FETCH_STATS_ERROR":
      case "MISSIONSREPORT_QUERY_STRING_UPDATED":
      case "MISSIONSREPORT_INIT_ON_REPORT_PAGE":
        return Object.assign({}, state, payload);
      case "MISSIONSREPORT_FETCH_STATS_FINISHED":
        return Object.assign({}, payload);
      case "URL_UPDATED":
        return Object.assign({}, state, { _shouldFetchStats: true });
      default:
        return state;
    }
  },
  addons: {
    doMissionsReportDownloadAsCsv:
      (data) =>
      ({ store }) => {
        const ignoreFields = ["id", "slug"];
        const formatFields = ["plan_sig", "mbo", "atpm", "review_sig"];
        if (!data || !data.length) return null;
        const csvRows = [];

        const decryptSignature = (sig) => JSON.parse(atob(sig.split(".")[0]));

        const fixCommas = (value) => {
          if (value && typeof value === "string" && value.indexOf(",") !== -1) {
            return `"${value.replace(/(\r\n|\n|\r)/gm, "")}"`;
          } else if (value && typeof value === "string")
            return value.replace(/(\r\n|\n|\r)/gm, "");
          else if (value && typeof value === "object" && value.length > 0)
            return `"${value.join(", ")}"`;
          return value;
        };

        const setHeaders = (item) => {
          const head = [];
          Object.keys(item).forEach((key) => {
            if (ignoreFields.indexOf(key) === -1)
              head.push(fixCommas(key.toUpperCase()));
          });
          csvRows.push(head.join(","));
        };

        data.forEach((item, i) => {
          if (i === 0) setHeaders(item);
          const row = [];
          Object.keys(item).forEach((key) => {
            if (formatFields.indexOf(key) !== -1)
              !item[key]
                ? row.push("")
                : row.push(decryptSignature(item[key]).username);
            else if (key === "description" || key === "name")
              row.push(fixCommas(item[key] && item[key].replaceAll(",", " ")));
            else if (ignoreFields.indexOf(key) === -1)
              row.push(fixCommas(item[key]));
          });
          csvRows.push(row.join(","));
        });

        const csv = csvRows.join("\n");
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, "export.csv");
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "export.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },

    doMissionsReportFetchStats:
      () =>
      ({ dispatch, store, apiGet }) => {
        dispatch({
          type: "MISSIONSREPORT_FETCH_STATS_STARTED",
          payload: {
            _shouldFetchStats: false,
            _isStatsLoading: true,
            _isLoading: true,
          },
        });

        const flags = store.selectMissionsReportFlags();
        const url = store.selectMissionsReportFetchStatsUrl();
        const isStale = store.selectMissionsReportIsStatsStale();
        const lastResource = store.selectMissionsReportLastStatsResource();

        if (url.indexOf("/:") !== -1) {
          dispatch({
            type: "MISSIONSREPORT_FETCH_STATS_ABORTED",
            payload: {
              _isStatsLoading: false,
            },
          });
        } else if (!isStale && url === lastResource) {
          dispatch({
            type: "MISSIONSREPORT_FETCH_STATS_ABORTED",
            payload: {
              _isStatsLoading: false,
            },
          });
        } else {
          apiGet(url, (err, response, body) => {
            if (err || response.statusCode !== 200) {
              dispatch({
                type: "MISSIONSREPORT_FETCH_STATS_ERROR",
                payload: {
                  _err: { err: err, response: response },
                  _isStatsLoading: false,
                  notification: {
                    statusCode: response.statusCode,
                  },
                },
              });
            } else {
              let data = JSON.parse(body);
              dispatch({
                type: "MISSIONSREPORT_FETCH_STATS_FINISHED",
                payload: {
                  ...flags,
                  _lastStatsResource: url,
                  _lastStatsFetch: new Date(),
                  _isStatsLoading: false,
                  _isLoading: false,
                  _queryString: {},
                  _stats: data,
                },
              });
            }
          });
        }
      },

    doMissionsReportUpdateQueryString:
      (update) =>
      ({ dispatch, store }) => {
        const q = store.selectMissionsReportQueryString();
        dispatch({
          type: "MISSIONSREPORT_QUERY_STRING_UPDATED",
          payload: {
            _queryString: {
              ...q,
              ...update,
            },
            // _shouldFetch: true,
          },
        });
        store.doMissionsReportFetch();
      },

    selectMissionsReportLastStatsResource: (state) => {
      return state.missionsReport._lastStatsResource;
    },

    selectMissionsReportStatsStaleAfter: (state) => {
      return state.missionsReport._statsStaleAfter;
    },

    selectMissionsReportLastStatsFetch: (state) => {
      return state.missionsReport._lastStatsFetch;
    },

    selectMissionsReportIsStatsStale: createSelector(
      "selectAppTime",
      "selectMissionsReportStatsStaleAfter",
      "selectMissionsReportLastStatsFetch",
      (now, staleAfter, lastFetch) => {
        return now - new Date(lastFetch) > staleAfter;
      }
    ),

    selectMissionsReportIsStatsLoading: (state) => {
      return state.missionsReport._isStatsLoading;
    },

    selectMissionsReportQueryString: (state) => {
      return state.missionsReport._queryString;
    },

    selectMissionsReportFormattedQueryString: createSelector(
      "selectMissionsReportQueryString",
      "selectMissionsReportStats",
      (queryString, stats) => {
        if (!queryString || !stats) return "";
        const qsItems = [];
        for (var key in queryString) {
          const val = queryString[key];

          // if there's nothing at this key, bail
          if (!val) continue;
          if (["plan_sig", "mbo", "atpm", "review_sig"].indexOf(key) !== -1)
            continue;
          // if it's an array with nothing in it it will fall to here, if nothing there then bail
          if (val.hasOwnProperty("length") && !val.length) continue;

          // if it's one of our time fields then handle that
          if (
            ["date_start", "date_end", "last_flight_date"].indexOf(key) !== -1
          ) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(
                `${key}_from=${encodeURIComponent(val.from.toISOString())}`
              );
            // if there is a value in the to key then add that
            if (val.to)
              qsItems.push(
                `${key}_to=${encodeURIComponent(val.to.toISOString())}`
              );
            continue;
          }

          // if it's one of our number fields then handle that

          if (
            ["max_alt", "total_flight_time", "pre_count", "post_count"].indexOf(
              key
            ) !== -1
          ) {
            // if there is a value in from then add that to the query string
            if (val.from)
              qsItems.push(`${key}_from=${encodeURIComponent(val.from)}`);
            // if there is a value in the to key and it's not the default then add that
            if (key === "max_alt" && val.to && val.to < stats.max_max_alt)
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            if (
              key === "total_flight_time" &&
              val.to &&
              val.to < stats.max_flight_time
            )
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            if (key === "pre_count" && val.to && val.to < stats.max_pre_count)
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            if (key === "post_count" && val.to && val.to < stats.max_post_count)
              qsItems.push(`${key}_to=${encodeURIComponent(val.to)}`);
            continue;
          }

          // otherwise, just add it to the querystring as-is
          qsItems.push(`${key}=${encodeURIComponent(val)}`);
        }

        if (!qsItems.length) return {};

        return {
          queryString: qsItems.join("&"),
        };
      }
    ),

    selectMissionsReportStats: (state) => {
      return state.missionsReport._stats || null;
    },

    selectMissionsReportFetchStatsUrl: createSelector(
      "selectRouteParams",
      (params) => {
        const availableParams = {
          ...params,
        };
        let url = "/orgs/:orgSlug/reports/missions/stats";
        Object.keys(availableParams).forEach((key) => {
          url = url.replace(`:${key}`, availableParams[key]);
        });
        return url;
      }
    ),

    selectMissionsReportParameters: createSelector(
      "selectMissionsReportStats",
      "selectDomainsItemsByGroup",
      "selectOrgsByRoute",
      (stats, domainsByGroup, org) => {
        if (!stats || !org) return null;
        const parameters = [];
        parameters.push({
          type: "checkbox-search",
          column: "org_name",
          title: "Organization",
          options: stats.orgs.map((o) => {
            return { id: o, val: o, checkedByDefault: org.name === o };
          }),
        });
        parameters.push({
          type: "text-search",
          column: "name",
          title: "Mission Name",
        });
        parameters.push({
          type: "text-search",
          column: "description",
          title: "Description",
        });
        parameters.push({
          type: "date-range-search",
          column: "date_start",
          title: "Mission Start Date",
        });
        parameters.push({
          type: "date-range-search",
          column: "date_end",
          title: "Mission End Date",
        });
        // parameters.push({
        //   type: "checkbox-search",
        //   column: "authority",
        //   title: "Authority",
        //   options: domainsByGroup["authority"].map((r) => {
        //     return { id: r.val, val: r.val };
        //   }),
        // });
        parameters.push({
          type: "number-range-search",
          column: "max_alt",
          title: "Max Altitude",
          min: 0,
          max: stats.max_max_alt || 0,
        });
        parameters.push({
          type: "checkbox-search",
          column: "flight_category",
          title: "Flight Category",
          options: domainsByGroup["flight_category"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "checkbox-search",
          column: "mission_type",
          title: "Mission Type",
          options: domainsByGroup["mission_type"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "checkbox-search",
          column: "overall_mission_task",
          title: "Overall Mission Task",
          options: domainsByGroup["overall_mission_task"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "checkbox-search",
          column: "flight_mode",
          title: "Flight Mode",
          options: domainsByGroup["flight_mode"].map((r) => {
            return { id: r.val, val: r.val };
          }),
        });
        parameters.push({
          type: "date-range-search",
          column: "last_flight_date",
          title: "Last Flight Date",
        });
        parameters.push({
          type: "checkbox-search",
          column: "archived",
          title: "Archived",
          options: [
            { id: "true", val: "true" },
            { id: "false", val: "false" },
          ],
        });
        parameters.push({
          type: "checkbox-search",
          column: "approval_status",
          title: "Approval Status",
          options: [
            { id: "draft", val: "draft" },
            { id: "pending approval", val: "pending approval" },
            { id: "approved", val: "approved" },
          ],
        });
        parameters.push({
          type: "checkbox-search",
          column: "schedule_status",
          title: "Schedule Status",
          options: [
            { id: "pending", val: "pending" },
            { id: "active", val: "active" },
            { id: "expired", val: "expired" },
          ],
        });
        parameters.push({
          type: "checkbox-search",
          column: "debrief_status",
          title: "Debrief Status",
          options: [
            { id: "pending", val: "pending" },
            { id: "in progress", val: "in progress" },
            { id: "complete", val: "complete" },
          ],
        });
        parameters.push({
          type: "checkbox-search",
          column: "mission_status",
          title: "Mission Status",
          options: [
            { id: "draft", val: "draft" },
            { id: "active", val: "active" },
            { id: "pending approval", val: "pending approval" },
            { id: "pending start date", val: "pending start date" },
            { id: "schedule expired", val: "schedule expired" },
            { id: "complete", val: "complete" },
          ],
        });
        parameters.push({
          type: "number-range-search",
          column: "total_flight_time",
          title: "Flight Minutes",
          min: 0,
          max: stats.max_flight_time || 0,
        });
        parameters.push({
          type: "date-range-search",
          column: "plan_sig",
          title: "Prepared Date",
        });
        parameters.push({
          type: "text-search",
          column: "plan_sig",
          title: "Preparer Name",
        });
        parameters.push({
          type: "date-range-search",
          column: "mbo",
          title: "MBO Signature Date",
        });
        parameters.push({
          type: "text-search",
          column: "mbo",
          title: "MBO Name",
        });
        parameters.push({
          type: "date-range-search",
          column: "atpm",
          title: "ATPM Signature Date",
        });
        parameters.push({
          type: "text-search",
          column: "atpm",
          title: "ATPM Name",
        });
        parameters.push({
          type: "text-search",
          column: "review_sig",
          title: "Quality Review Name",
        });
        parameters.push({
          type: "date-range-search",
          column: "review_sig",
          title: "Quality Review Date",
        });
        parameters.push({
          type: "number-range-search",
          column: "pre_count",
          title: "Adjudications Pre Approval",
          min: 0,
          max: stats.max_pre_count || 0,
        });
        parameters.push({
          type: "number-range-search",
          column: "post_count",
          title: "Adjudications Post Approval",
          min: 0,
          max: stats.max_post_count || 0,
        });
        return parameters;
      }
    ),

    reactMissionsReportShouldFetchStats: (state) => {
      if (state.missionsReport._shouldFetchStats)
        return { actionCreator: "doMissionsReportFetchStats" };
    },
  },
});
