import React from "react";
import { connect } from "redux-bundler-react";

const URL_BASE_PATH = import.meta.env.VITE_URL_BASE_PATH;
const fullPageRoutes = [
  `${URL_BASE_PATH}/backlog`,
  `${URL_BASE_PATH}/coa-backlog`,
  `${URL_BASE_PATH}/equipment-requests`,
  `${URL_BASE_PATH}/equipment-requests/:equipmentRequestSlug`,
  `${URL_BASE_PATH}/references`,
  `${URL_BASE_PATH}/release-notes`,
  `${URL_BASE_PATH}/profile`,
  `${URL_BASE_PATH}/faqs`,
  `${URL_BASE_PATH}/job-board`,
  `${URL_BASE_PATH}/configurations`,
  `${URL_BASE_PATH}/job-board/:jobSlug`,
  `${URL_BASE_PATH}/read-file`,
  `${URL_BASE_PATH}/job-board/new`,
];
class FullPageProvider extends React.Component {
  render() {
    const { children, route: Route, routeInfo } = this.props;
    if (fullPageRoutes.indexOf(routeInfo.pattern) !== -1) {
      return <Route />;
    } else {
      return <>{children}</>;
    }
  }
}

export default connect("selectRoute", "selectRouteInfo", FullPageProvider);
