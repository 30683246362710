import { createRouteBundle } from "redux-bundler";
import Home from "../app-components/app-home/home";
import {
  AtpmManager,
  Dashboard,
  EquipmentRequestsPage,
  EquipmentRequestDetails,
  GCS,
  GCSNew,
  GCSDetails,
  Aircraft,
  AircraftNew,
  AircraftDetails,
  Backlog,
  Batteries,
  BatteriesNew,
  BatteriesDetails,
  Payloads,
  PayloadsNew,
  PayloadsDetails,
  Personnel,
  PersonnelNew,
  PersonnelDetails,
  Planner,
  MissionNew,
  MissionDetails,
  GoFly,
  Debrief,
  Archive,
  AllPlatforms,
  // PlatformsNew,
  PlatformsDetails,
  ReferenceLibrary,
  ReleaseNotesManager,
  Users,
  Domains,
  RoleManager,
  Transfers,
  TransferNew,
  TransferDetails,
  TransferEquipment,
  PersonnelReport,
  JobBoardHome,
  JobNew,
  JobItemPage,
  MessageBoardHome,
  MessageItemPage,
  MissionReport,
  AircraftReport,
  BatteriesReport,
  AllEquipment,
  MMS,
  MMSNew,
  MMSDetails,
  Faqs,
  ReadFile,
  CoaBacklog,
} from "../modules/route-roots";

import FourOhFour from "../app-components/four-oh-four";
import ComingSoon from "../app-components/coming-soon";
import Profile from "../app-containers/profile/profile";
import ConfigurationsPage from "../modules/configurations";

const base = import.meta.env.VITE_URL_BASE_PATH;

export default createRouteBundle(
  {
    [`${base}`]: Home,
    [`${base}/`]: Home,
    [`${base}/backlog`]: Backlog,
    [`${base}/coa-backlog`]: CoaBacklog,
    [`${base}/:orgSlug/equipment-requests`]: EquipmentRequestsPage,
    [`${base}/:orgSlug/equipment-requests/:equipmentRequestSlug`]: EquipmentRequestDetails,
    [`${base}/:orgSlug/aircraft/equipment-requests/:equipmentRequestSlug`]: EquipmentRequestDetails,
    [`${base}/:orgSlug/gcs/equipment-requests/:equipmentRequestSlug`]: EquipmentRequestDetails,
    [`${base}/:orgSlug/payloads/equipment-requests/:equipmentRequestSlug`]: EquipmentRequestDetails,
    [`${base}/configurations`]: ConfigurationsPage,
    [`${base}/references`]: ReferenceLibrary,
    [`${base}/read-file`]: ReadFile,
    [`${base}/release-notes`]: ReleaseNotesManager,
    [`${base}/profile`]: Profile,
    [`${base}/profile/:profileId`]: Profile,
    [`${base}/faqs`]: Faqs,
    [`${base}/job-board`]: JobBoardHome,
    [`${base}/job-board/new`]: JobNew,
    [`${base}/job-board/:jobSlug`]: JobItemPage,

    [`${base}/:orgSlug`]: Dashboard,
    [`${base}/:orgSlug/atpm`]: AtpmManager,
    [`${base}/:orgSlug/allEquipment`]: AllEquipment,
    [`${base}/:orgSlug/aircraft`]: Aircraft,
    [`${base}/:orgSlug/aircraft/new`]: AircraftNew,
    [`${base}/:orgSlug/aircraft/:aircraftSlug`]: AircraftDetails,

    [`${base}/:orgSlug/batteries`]: Batteries,
    [`${base}/:orgSlug/batteries/new`]: BatteriesNew,
    [`${base}/:orgSlug/batteries/:batteriesSlug`]: BatteriesDetails,

    [`${base}/:orgSlug/payloads`]: Payloads,
    [`${base}/:orgSlug/payloads/new`]: PayloadsNew,
    [`${base}/:orgSlug/payloads/:payloadsSlug`]: PayloadsDetails,

    [`${base}/:orgSlug/gcs`]: GCS,
    [`${base}/:orgSlug/gcs/new`]: GCSNew,
    [`${base}/:orgSlug/gcs/:gcsSlug`]: GCSDetails,

    [`${base}/:orgSlug/mms`]: MMS,
    [`${base}/:orgSlug/mms/new`]: MMSNew,
    [`${base}/:orgSlug/mms/:mmsSlug`]: MMSDetails,

    [`${base}/:orgSlug/personnel`]: Personnel,
    [`${base}/:orgSlug/personnel/new`]: PersonnelNew,
    [`${base}/:orgSlug/personnel/:personnelSlug`]: PersonnelDetails,

    [`${base}/:orgSlug/planner`]: Planner,
    [`${base}/:orgSlug/planner/new`]: MissionNew,

    [`${base}/:orgSlug/fly`]: GoFly,
    [`${base}/:orgSlug/debrief`]: Debrief,
    [`${base}/:orgSlug/archive`]: Archive,

    [`${base}/:orgSlug/message-board`]: MessageBoardHome,
    [`${base}/:orgSlug/message-board/:messageSlug`]: MessageItemPage,

    [`${base}/:orgSlug/mission/:missionsSlug`]: MissionDetails,

    [`${base}/:orgSlug/settings`]: ComingSoon,

    [`${base}/:orgSlug/platforms`]: AllPlatforms,
    // [`${base}/:orgSlug/platforms/new`]: PlatformsNew,
    [`${base}/:orgSlug/platforms/:platformsSlug`]: PlatformsDetails,

    [`${base}/:orgSlug/transfers`]: Transfers,
    [`${base}/:orgSlug/transfers/new`]: TransferNew,
    [`${base}/:orgSlug/transfers/:transferSlug`]: TransferDetails,
    [`${base}/:orgSlug/transfers/:transferSlug/equipment`]: TransferEquipment,

    [`${base}/:orgSlug/users`]: Users,

    [`${base}/:orgSlug/roles`]: RoleManager,

    [`${base}/:orgSlug/domains`]: Domains,

    [`${base}/:orgSlug/reports/personnel`]: PersonnelReport,
    [`${base}/:orgSlug/reports/aircraft`]: AircraftReport,
    [`${base}/:orgSlug/reports/batteries`]: BatteriesReport,
    [`${base}/:orgSlug/reports/missions`]: MissionReport,

    "*": FourOhFour,
  },
  {
    routeInfoSelector: "selectPathname",
  }
);
