import React from "react";
import Loader from "../../../app-components/loader";
import Grid from "../grid";
import { connect } from "redux-bundler-react";
import { customComparator } from "../helper";

class FileIOButton {
  init(params) {
    const eGui = (this.eGui = document.createElement("div"));
    eGui.className = `d-flex justify-content-center w-100 h-100`;
    eGui.innerHTML = `
          <button class="btn btn-sm my-auto btn-${
            params.data.editing ? "ghost-danger" : "info"
          }">
              <i class="mdi mdi-${
                params.data.editing ? "delete" : "download"
              }"></i>
          </button>
      `;
    let button = eGui.querySelector("button");

    this.eventListener = () => {
      params.colDef.onBtnClick(params.data);
    };
    button.addEventListener("click", this.eventListener);
  }
  getGui() {
    return this.eGui;
  }
}

const FileList = ({
  doFileIoDownload,
  items,
  editing,
  isLoading,
  onRemove,
  removeUrl,
  rel,
  showDate,
  scrollable,
  additionalColumns = [],
}) => {
  const config = {
    tooltipShowDelay: 0,
    suppressScrollOnNewData: true,
    domLayout: "autoHeight",
    defaultColDef: { resizable: true },
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "",
        comparator: customComparator,
        cellRenderer: FileIOButton,
        onBtnClick: (data) => {
          if (data.editing) {
            onRemove(
              removeUrl,
              data.s3_key,
              rel,
              rel === "platform_docs" || rel === "platforms_awr_docs"
            );
          } else doFileIoDownload(data.s3_key);
        },
        width: 60,
      },
      {
        headerName: "Filename",
        field: "filename",
        comparator: customComparator,
        width: 300,
        cellRenderer: (params) => {
          return (
            <div className="d-flex h-100 w-100">
              <span className="my-auto">{params.data.filename} </span>
            </div>
          );
        },
      },
      ...additionalColumns,
    ],
    onGridReady: (params) => {
      params.api.sizeColumnsToFit();
    },
  };

  if (isLoading) return <Loader />;
  if (!items.length) {
    return (
      <ul
        className="list-group"
        style={
          scrollable
            ? {
                height: items.length >= 3 ? 165 : "auto",
                overflowY: "scroll",
              }
            : null
        }
      >
        <li className="list-group-item">No Associated Documents...</li>
      </ul>
    );
  } else {
    return (
      <Grid
        config={config}
        data={items.map((i) => {
          return {
            ...i,
            editing: editing,
            onRemove: onRemove,
            removeUrl: removeUrl,
            rel: rel,
            showDate: showDate,
          };
        })}
        style={{ width: "100%" }}
      />
    );
  }
};
export default connect(
  "doFileIoDownload",
  "doDialogOpen",
  "doLibrarySave",
  FileList
);
