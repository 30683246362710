import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import transferAtpmModal from "./transfer-atpm-modal";
import "./cards/card.css";
import CurrencyCard from "./cards/currency-card";
import SignaturesCard from "./cards/signatures-card";
import RequestsCard from "./cards/requests-card";
import TransfersCard from "./cards/transfers-card";
import ApprovalRoleFilter from "../../app-containers/context-providers/approval-role-filter";
import manageTransfersModal from "./manage-transfers-modal";
import { barryOrTyler } from "../_shared/sysadmin-users";
import ReactSelect from "react-select";
import { sortBy } from "lodash";

const AtpmManager = ({
  doFetchAtpmDashboardById,
  doDialogOpen,
  profileActiveIsFetching,
  profileId,
  atpmTransferItems,
  transfersIsLoading,
  backlogIsLoading,
  accessRequestIsLoading,
  atpmTransferCurrentUserBackup,
  atpmTransferCurrentUserAtpm,
  doFetchProfilesByOrg,
  atpmControlIsSaving,
  doReadFileFetchAll,
  tokenKeyCloakId,
  approvalRolesAtpms,
  doSetActiveAtpm,
  activeAtpm,
  doApprovalRolesFetch,
}) => {
  useEffect(() => {
    doReadFileFetchAll();
    doFetchAtpmDashboardById(profileId);
    doFetchProfilesByOrg();
    doApprovalRolesFetch();
  }, []);

  useEffect(() => {
    let active = approvalRolesAtpms?.filter(
      (atpm) => atpm.parent_id === profileId
    );
    doSetActiveAtpm({
      value: active && active[0] && active[0].parent_id,
      label: active && active[0] && active[0].name,
    });
  }, [approvalRolesAtpms]);

  const currentTransfer = atpmTransferItems.filter(
    (x) => x.backup_atpm_id === profileId
  )[0];
  return (
    <div className="container-fluid mt-4">
      {barryOrTyler.includes(tokenKeyCloakId) && (
        <div className={`alert alert-info`} role="alert">
          <h5 className="alert-heading">View Other ATPM's Dashboard</h5>
          <div className="d-flex flex-column">
            <label>Select ATPM</label>
            <ReactSelect
              options={sortBy(approvalRolesAtpms, "name").map((item) => ({
                value: item.parent_id,
                label: item.name,
              }))}
              onChange={(obj) =>
                doFetchAtpmDashboardById(obj.value, () => doSetActiveAtpm(obj))
              }
            />
          </div>
          <hr />
          Viewing: <b>{activeAtpm.label}</b>
        </div>
      )}
      {atpmTransferCurrentUserBackup &&
        atpmTransferCurrentUserBackup.length > 0 && (
          <div className={`alert alert-info`} role="alert">
            <h5 className="alert-heading">Active ATPM via Transfer</h5>
            <p>
              You have been temporarily transferred ATPM authority by the
              user(s) below. The crewmembers below managed by the primary ATPM
              are denoted with{" "}
              <i className="mdi mdi-account-switch icon-inline mdi-18px"></i>
            </p>
            <hr />
            <b>{atpmTransferCurrentUserBackup.map((a) => a.name).join(", ")}</b>
          </div>
        )}
      {atpmTransferCurrentUserAtpm &&
        atpmTransferCurrentUserAtpm.length > 0 && (
          <div className={`alert alert-info`} role="alert">
            <h5 className="alert-heading">ATPM Authority Transferred</h5>
            <p>
              You have temporarily transferred ATPM authority to the user(s)
              below. You can still manage your crewmembers as usual.
            </p>
            <hr />
            <b>{atpmTransferCurrentUserAtpm.map((a) => a.name).join(", ")}</b>
          </div>
        )}
      <div className="d-flex align-items-center justify-content-between pb-2 mb-4">
        <div className="card w-100">
          <div className="card-header d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <i className="mdi mdi-24px mdi-view-dashboard mr-2" />
              <h5 className="m-0">ATPM Dashboard</h5>
            </div>
            <ApprovalRoleFilter
              allowRoles={["ATPM"]}
              alt={() => {
                return (
                  <div className="d-flex align-items-center">
                    <p className="text-muted d-flex m-0">
                      <b className="mr-1">Backup ATPM Expiration:</b>
                      {currentTransfer &&
                        currentTransfer.expiration_date.split("T")[0]}
                    </p>
                  </div>
                );
              }}
            >
              {activeAtpm.value === profileId && (
                <div className="d-flex">
                  <button
                    className="btn btn-sm btn-ghost-dark"
                    onClick={() => {
                      doDialogOpen({
                        content: transferAtpmModal,
                        props: {},
                      });
                    }}
                  >
                    <i className="mdi mdi-account-switch icon-inline mdi-18px"></i>
                    Transfer ATPM Privileges
                  </button>
                  {atpmTransferCurrentUserAtpm &&
                    atpmTransferCurrentUserAtpm.length > 0 && (
                      <button
                        className="btn btn-sm btn-ghost-primary"
                        onClick={() => {
                          doDialogOpen({
                            content: manageTransfersModal,
                            props: {},
                          });
                        }}
                      >
                        <i className="mdi mdi-account-multiple icon-inline mdi-18px"></i>
                        Manage Transfers
                      </button>
                    )}
                </div>
              )}
            </ApprovalRoleFilter>
          </div>
          <div className="card-body">
            <div className="custom-body">
              <CurrencyCard
                isLoading={profileActiveIsFetching || atpmControlIsSaving}
              />
              <TransfersCard
                isLoading={profileActiveIsFetching || transfersIsLoading}
              />
              <SignaturesCard
                isLoading={profileActiveIsFetching || backlogIsLoading}
              />
              <RequestsCard
                isLoading={profileActiveIsFetching || accessRequestIsLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doFetchAtpmDashboardById",
  "doDialogOpen",
  "selectProfileActiveData",
  "selectProfileActiveIsFetching",
  "selectProfileId",
  "selectAtpmTransferItems",
  "selectTransfersIsLoading",
  "selectBacklogIsLoading",
  "selectAccessRequestIsLoading",
  "selectAtpmTransferCurrentUserBackup",
  "selectAtpmTransferCurrentUserAtpm",
  "doFetchProfilesByOrg",
  "selectAtpmControlIsSaving",
  "doReadFileFetchAll",
  "selectTokenKeyCloakId",
  "selectApprovalRolesAtpms",
  "doSetActiveAtpm",
  "selectActiveAtpm",
  "doApprovalRolesFetch",
  AtpmManager
);
