import React from "react";
import { connect } from "redux-bundler-react";
import { isEmpty, keyBy } from "lodash";
import { platforms, platformsGcs, platformsPayloads } from "../../models";
import Loader from "../../app-components/loader";
import SchemaForm from "../../app-components/schema-form/schema-form";
import FileUploadCard from "../_shared/file-upload-card/file-upload-card";
import DeleteButton from "../_shared/delete-button/delete-button";
import NotesCard from "../_shared/notes-card/note-card";
import Grid from "../_shared/grid";
import { allowedIds } from "../_shared/sysadmin-users";

class PlatformDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.save = this.save.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.enableEditing = this.enableEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.renderGridData = this.renderGridData.bind(this);
  }

  save() {
    const {
      doPlatformsPut,
      doPlatformsGcsPut,
      doUpdateRelativeUrl,
      platformsByRoute,
      routeParams,
      platformsGcsByRoute,
      doPlatformsPayloadsPut,
      platformsPayloadsByRoute,
      doFileIoUpload,
      doFileIoDelete,
    } = this.props;
    let platform = platformsByRoute
      ? platformsByRoute
      : platformsGcsByRoute
        ? platformsGcsByRoute
        : platformsPayloadsByRoute;
    let doSave = platformsByRoute
      ? doPlatformsPut
      : platformsGcsByRoute
        ? doPlatformsGcsPut
        : doPlatformsPayloadsPut;
    if (this.form.isValid()) {
      const data = this.form.serialize();
      const item = Object.assign({}, platform, data);
      // if schema contains image then callback for file upload
      // if image already had existed we need to remove the old image...
      // if(item.image_s3_key || item.image_filename) would mean that already exists
      this.setState(
        {
          editing: false,
        },
        () => {
          if (item.image) {
            doSave(
              item,
              (newPlatform) => {
                if (item.image_s3_key) {
                  doFileIoDelete(
                    null,
                    item.image_s3_key,
                    "platforms_image_docs",
                    true,
                    () =>
                      doFileIoUpload(null, item.image, "platforms_image_docs", {
                        platform_id: newPlatform.id,
                      })
                  );
                } else {
                  doFileIoUpload(null, item.image, "platforms_image_docs", {
                    platform_id: newPlatform.id,
                  });
                }
              },
              true
            );
          } else doSave(item);
        }
      );
    }
  }

  enableEditing() {
    this.setState({
      editing: true,
    });
  }

  cancelEditing() {
    const { platformsByRoute, platformsGcsByRoute, platformsPayloadsByRoute } =
      this.props;
    let platform = platformsByRoute
      ? platformsByRoute
      : platformsGcsByRoute
        ? platformsGcsByRoute
        : platformsPayloadsByRoute;
    let schema = platformsByRoute
      ? platforms
      : platformsGcsByRoute
        ? platformsGcs
        : platformsPayloads;
    this.setState(
      {
        editing: false,
      },
      () => {
        this.form.reset({ data: platform, schema });
      }
    );
  }

  handleDelete() {
    const {
      doPlatformsDelete,
      doPlatformsGcsDelete,
      doPlatformsPayloadsDelete,
      doUpdateRelativeUrl,
      routeParams,
      platformsByRoute,
      platformsGcsByRoute,
      platformsPayloadsByRoute,
    } = this.props;
    let doDelete = platformsByRoute
      ? doPlatformsDelete
      : platformsGcsByRoute
        ? doPlatformsGcsDelete
        : doPlatformsPayloadsDelete;
    let platform = platformsByRoute
      ? platformsByRoute
      : platformsGcsByRoute
        ? platformsGcsByRoute
        : platformsPayloadsByRoute;
    this.setState(
      {
        editing: false,
      },
      () => {
        doDelete(platform, () => {
          doUpdateRelativeUrl(`/${routeParams.orgSlug}/platforms`);
        });
      }
    );
  }

  renderForm() {
    const { editing } = this.state;
    const {
      platformsByRoute,
      platformsGcsByRoute,
      platformsPayloadsByRoute,
      tokenKeyCloakId,
    } = this.props;
    let platform = platformsByRoute
      ? platformsByRoute
      : platformsGcsByRoute
        ? platformsGcsByRoute
        : platformsPayloadsByRoute;
    let schema = platformsByRoute
      ? platforms
      : platformsGcsByRoute
        ? platformsGcs
        : platformsPayloads;
    if (isEmpty(platform)) return <Loader />;
    return (
      <SchemaForm
        ref={(el) => {
          this.form = el;
        }}
        inline={true}
        displayOnly={!editing}
        schema={schema}
        data={platform}
        conditionalInputs={[
          {
            name: "benign_approved",
            readOnly: !allowedIds.includes(tokenKeyCloakId),
          },
          {
            name: "usace_approved",
            readOnly: !allowedIds.includes(tokenKeyCloakId),
          },
          {
            name: "awr_number",
            readOnly: !allowedIds.includes(tokenKeyCloakId),
          },
          {
            name: "awr_expire_date",
            readOnly: !allowedIds.includes(tokenKeyCloakId),
          },
        ]}
      />
    );
  }

  renderActions() {
    const { editing } = this.state;
    if (!editing) return null;
    return (
      <div>
        <div className="float-right">
          <button
            onClick={this.cancelEditing}
            className="btn btn-sm btn-secondary mr-2"
          >
            Cancel
          </button>
          <button onClick={this.save} className="btn btn-sm btn-success">
            Save
          </button>
        </div>
        <DeleteButton onDelete={this.handleDelete} />
      </div>
    );
  }

  renderGridData(id) {
    const { platformsItems, aircraftConfigurationItems, platformsByRoute } =
      this.props;
    let filtered = aircraftConfigurationItems.filter((item) => {
      return item.aircraft_id === platformsByRoute.id && item.approved;
    });
    filtered = filtered.filter((item) => !!item[id]);
    filtered.sort((a, b) => {
      if (a.model > b.model) return 1;
      else return -1;
    });
    return filtered;
  }

  render() {
    const { editing } = this.state;
    const {
      platformsByRoute,
      platformsGcsByRoute,
      platformsPayloadsByRoute,
      platformsDocsItems,
      platformsDocsIsLoading,
      platformsIsLoading,
      doFileIoUpload,
      doFileIoDelete,

      platformAwrDocs,
      platformConfigurationDocs,
      tokenKeyCloakId,
      orgsByRoute,
    } = this.props;
    let platform = platformsByRoute
      ? platformsByRoute
      : platformsGcsByRoute
        ? platformsGcsByRoute
        : platformsPayloadsByRoute;

    const config = (listedKeyPrefix) => {
      return {
        domLayout: "autoHeight",
        tooltipShowDelay: 0,
        suppressScrollOnNewData: true,
        defaultColDef: { resizable: true },
        columnDefs: [
          {
            headerName: "Make",
            field: [`${listedKeyPrefix}_make`],
            sortable: true,
          },
          {
            headerName: "Model",
            field: [`${listedKeyPrefix}_model`],
            cellRenderer: (params) => {
              return (
                <a
                  href={`/${orgsByRoute.slug}/platforms/${params.data[`${listedKeyPrefix}_slug`]
                    }`}
                >
                  {params.data[`${listedKeyPrefix}_model`]}
                </a>
              );
            },
          },
        ],
      };
    };
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-7">
            <div className="card">
              <div className="card-header">
                <div className="float-right">
                  <button
                    disabled={editing}
                    className="btn btn-sm btn-ghost-primary"
                    onClick={this.enableEditing}
                  >
                    <i className="mdi mdi-pencil icon-inline"></i>
                    Edit
                  </button>
                </div>
                <i className="mdi mdi-cog"></i> Platform Details
              </div>
              <div className="card-body">
                {this.renderForm()}
                {this.renderActions()}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            {/** Dont render AWR doc if its GCS or Payload */}
            {platformsByRoute && (
              <FileUploadCard
                title="AWR Document"
                allowRoles={
                  allowedIds.includes(tokenKeyCloakId)
                    ? [":ORG.MEMBER", ":ORG.ADMIN"]
                    : []
                }
                isLoading={platformsIsLoading}
                items={platformsByRoute ? platformAwrDocs : []}
                header={true}
                searchable={false}
                onUpload={doFileIoUpload}
                onRemove={doFileIoDelete}
                rel="platforms_awr_docs"
                data={{
                  platform_id: platformsByRoute ? platformsByRoute.id : null,
                }}
                disabledEditing={platformAwrDocs.length > 0}
              />
            )}
            {platformsByRoute && (
              <FileUploadCard
                title="SUAS Configuration Document"
                allowRoles={["HQ.ADMIN"]}
                isLoading={platformsIsLoading}
                items={platformsByRoute ? platformConfigurationDocs : []}
                header={true}
                searchable={false}
                onUpload={doFileIoUpload}
                onRemove={doFileIoDelete}
                rel="platforms_configuration_docs"
                data={{
                  platform_id: platformsByRoute ? platformsByRoute.id : null,
                }}
                disabledEditing={platformConfigurationDocs.length > 0}
              />
            )}
            <FileUploadCard
              allowRoles={[":ORG.MEMBER", ":ORG.ADMIN"]}
              isLoading={platformsDocsIsLoading}
              header={true}
              searchable={false}
              items={platformsDocsItems}
              onUpload={doFileIoUpload}
              onRemove={doFileIoDelete}
              rel="platform_docs"
              data={{
                platform_id: platform ? platform.id : null,
              }}
            />
            {platformsByRoute && (
              <div className="w-100">
                <div className="card approved-components-card">
                  <div className="card-header">
                    <i className="mdi mdi-airplane" />{" "}
                    <span>Approved Payloads</span>
                  </div>
                  <div className="card-body">
                    <Grid
                      config={config("payload")}
                      data={this.renderGridData("payload_id")}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="card approved-components-card">
                  <div className="card-header">
                    <i className="mdi mdi-airplane" /> <span>Approved GCS</span>
                  </div>
                  <div className="card-body">
                    <Grid
                      config={config("gcs")}
                      data={this.renderGridData("gcs_id")}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <NotesCard />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  "doUpdateRelativeUrl",
  "doFileIoUpload",
  "doFileIoDelete",
  "doPlatformsPut",
  "doPlatformsGcsPut",
  "doPlatformsPayloadsPut",
  "doPlatformsDelete",
  "doPlatformsGcsDelete",
  "doPlatformsPayloadsDelete",
  "selectPlatformsByRoute",
  "selectPlatformsGcsByRoute",
  "selectPlatformsPayloadsByRoute",
  "selectPlatformsDocsItems",
  "selectPlatformsDocsIsLoading",
  "selectPlatformsGcsIsLoading",
  "selectPlatformsPayloadsIsLoading",
  "selectPlatformsIsLoading",
  "selectRouteParams",
  "selectPathname",
  "selectPlatformAwrDocs",
  "selectPlatformConfigurationDocs",
  "selectTokenKeyCloakId",
  "selectOrgsByRoute",
  "selectAircraftConfigurationItems",
  "selectPlatformsItems",
  PlatformDetails
);
